<template>
  <div class="member">
      <div class="member__image">
          <img :src="require(`@/assets/gallery/team/${data.image}`)" alt="">
          <div v-if="data.image_2" class="member__image--action">
                <img :src="require(`@/assets/gallery/team/${data.image_2}`)" alt="">
          </div>
      </div>
      <div class="member__content">
          <h2>
              {{data.name}}
          </h2>
          <h3>
              {{data.role}}
          </h3>
          <p>
              {{data.text}}
          </p>
          <a v-if="data.email" :href="`mailto:${data.email}`">
              <img src="@/assets/icons/email.svg" alt="email image">
              {{data.email}}
          </a>
      </div>
  </div>
</template>

<script>
export default {
    props:['data']
}
</script>

<style lang="scss" scoped>

@import '@/style/main';
.member{
    flex: 0 0 48.5%;
    display: flex;
    background-color: $color-white;
    margin-bottom: 3%;
    border-radius: 3px;
    box-shadow: $shadow;
    overflow: hidden;

    @include respond(phone){
        flex-direction: column;
        margin-bottom: 4rem;
    }

    &:hover > &__image > &__image--action{
        left: 0;
    }
    &__image{
        position: relative;
        flex: 0 0 50%;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            
        }

        &--action{
            position: absolute;
            top: 0;
            left: -100%;
            height: 100%;
            width: 100%;

            transition: all .3s;
        }
    }   
    &__content{
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;

        & h2{
            text-transform: uppercase;
            text-align: center;
        }
        & h3{
            font-weight: 300;
            text-align: center;
        }

        & p{
            margin: 2rem 0;
        }

        & a{
            display: flex;
            align-items: center;
            text-decoration: none;
            color:inherit;
            text-transform: uppercase;
            & img{
                height: 1.6rem;
                width: auto;
                margin-right: 1rem;
            }
        }   
    }
    
}


</style>