<template>
    <div class="page team">
    <page-section>
          <heading-card :data="dataHeading"></heading-card>
          <section-title>{{t('why.title')}}</section-title>
          <p>
           {{t('why.text')}}
          </p>
  </page-section>
  <page-section id="teammates" color="grey">
         <section-title>
           {{t('team.title')}}
         </section-title>
         <div class="team__gallery">
           <team-card v-for="item in team" :key="item.email" :data="item"></team-card>
         </div>
  </page-section>
  <page-section>
         <section-title logo="sotrad-water-small.png" link="https://www.sotradwater.be">
          {{t('sotrad.title')}}
         </section-title>
         <div class="team__item">
            <h2>
              {{t('sotrad.creation.title')}}
            </h2>
            <p>
              {{t('sotrad.creation.text')}}
            </p>
         </div>
        <div class="team__item">
          <h2>
            {{t('sotrad.expertise.title')}}
          </h2>
          <p>
            {{t('sotrad.expertise.text')}}
          </p>
          </div>
         <div class="team__item">
            <h2>
            {{t('sotrad.values.title')}}
          </h2>
          <p>
            {{t('sotrad.values.text')}}
          </p>
          </div>
  </page-section>
  <page-section color="grey">
        <section-title>{{t('partners.title')}}</section-title>
        <div class="partners">
          <a :href="partner.link" target="_blank" ref="nofollow" v-for="partner in partners" :key="partner.logo" class="partners__item">
            <img :src="require(`@/assets/gallery/partners/${partner.logo}`)" alt="">
          </a>
        </div>
  </page-section>
  </div>
</template>

<script>
import HeadingCard from '@/components/HeadingCard';
import PageSection from '@/components/PageSection';
import TeamCard from '@/components/team/TeamCard';

import SectionTitle from '@/components/section/SectionTitle';

import {useI18n} from 'vue-i18n';
import { computed } from 'vue';

export default {
    components:{
      HeadingCard,
      PageSection,
      SectionTitle,
      TeamCard
    },
    setup(){
      const {t} = useI18n({
          messages:{
            en: require('@/locales/en/team.json'),
            fr: require('@/locales/fr/team.json'),
            pt: require('@/locales/pt/team.json'),
            sw: require('@/locales/sw/team.json')
          }
      })
      const dataHeading = computed(() => {
        return  {
        title: t('title'),
        image:'meet-the-team.jpg',
        alt: t('alt'),
        cta:{
            type:"scroll",
            target: 'teammates',
            icon: 'team',
            main: t('cta.main'),
            text: t('cta.text')
        }
      }});
      const team = computed(() =>  [
        {
          name:"Raoul Antoine",
          role: t('team.members.raoul.role'),
          text: t('team.members.raoul.text'),
          email:"ra@sotradwater.be",
          image:"raoul.png",
          image_2:"ra.jpg"
        },
         {
          name:"Jean Charles de Muylder",
          role: t('team.members.jc.role'),
          text: t('team.members.jc.text'),
          email:"jchd@sotradwater.be",
          image:"jp.png",
          image_2:"jc.jpg"
        },
         {
          name:"Benoit Tondeur",
          role: t('team.members.benoit.role'),
          text: t('team.members.benoit.text'),
          email:"bt@sotradwater.be",
          image:"benoit.png",
          image_2:"bt.jpg"
        },
         {
          name:"Giovanni Caruso",
          role: t('team.members.giovanni.role'),
          text: t('team.members.giovanni.text'),
          email:"",
          image:"giovanni-1.jpg",
          image_2:"giovanni-2.jpg"
        },
        {
          name:"Pascal Boudry",
          role: t('team.members.pascale.role'),
          text: t('team.members.pascale.text'),
          email:"",
          image:"pascale.jpg",
          image_2:"pb.png"
        },
        {
          name:"Orcel Kouandzi",
          role: t('team.members.orcel.role'),
          text: t('team.members.orcel.text'),
          email:"",
          image:"orcel.jpg",
          image_2:"orcel.png"
        },
        {
          name:"Alexandre Clerbois",
          role: t('team.members.alexandre.role'),
          text: t('team.members.alexandre.text'),
          email:"",
          image:"ac.jpg",
          image_2:false
        },
        {
          name:"Maxence Frébutte ",
          role: t('team.members.maxence.role'),
          text: t('team.members.maxence.text'),
          email:"",
          image:"mf.jpg",
          image_2:false
        },
        {
          name:"Mathieu Maréchal",
          role: t('team.members.mathieu.role'),
          text: t('team.members.mathieu.text'),
          email:"",
          image:"mm.jpg",
          image_2:false
        }
      ]);

      const partners = [
        {
          name:"Pipeco",
          logo:"pipeco.png",
          link: "https://www.pipeco.com.my/"
        },
        {
          name:"Dosatron",
          logo:"dosatron.png",
          link: "https://www.dosatron.com/fr/traitement-de-leau/potabilisation"
        },
        {
          name:"Estia",
          logo:"estia.jpg",
          link: "https://www.estiasynergie.com/"
        },
        {
          name:"Sinoqualis",
          logo:"sinoqualis.png",
          link: "https://www.sinoqualis.com/"
        },
         {
          name:"Staizen",
          logo:"staizen.png",
          link: "https://www.staizen.com/"
        },
         {
          name:"Scc Mike Kasenga",
          logo:"steve-construct.png",
          link: "#"
        },
         {
          name:"Advansos",
          logo:"advansos.png",
          link: "#"
        },
        {
          name:"Larica Investments Sl",
          logo:"larica.png",
          link: "#"
        }
      ]
      return{
        dataHeading,
        partners,
        t,
        team
      }
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/main';
  .team{
    & h2{
      margin-bottom: .5rem;
    }

    &__item{

      & h2{
        text-transform: uppercase;
      }
      &:not(:last-child){
        margin-bottom: 3rem;
      }
    }

    &__gallery{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include respond(phone){
        flex-direction: column;
      }
    }
  }
  .partners{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include respond(phone){
      flex-direction: column;
    }
    &__item{
      text-decoration: none;
      color:inherit;
      text-transform: uppercase;
      // height: 8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $color-white;
      border-radius: 5px;
      padding: 1.5rem 2.5rem;
      box-shadow: $shadow;
      margin-bottom: 2rem;



      &:not(:last-child){
        margin-right: 2rem;

        @include respond(phone){
          margin-right: 0;
        }
      }

      & img{
        height: 4.8rem;
        width: auto;
        // margin-bottom: 1rem;
      }

      
    }
  }
</style>
